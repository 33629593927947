(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('EmpregadorDialogController', EmpregadorDialogController);

    EmpregadorDialogController.$inject = ['ParseLinks', '$localStorage', '$timeout', '$state', '$window', '$rootScope', '$scope', '$stateParams',
        'entity', 'Empregador', '$uibModal', 'Principal', 'Auth', 'BuscaCep', 'Upload', 'RamoAtuacaoEmpresa', 'AtributoGenerico',
        'Termo', 'PlanoDestaque', 'Pessoa', 'pagingParams', 'ConfirmService', '$location', '$http', '$sce', 'Curso', 'Parceiro', 'User'];

    function EmpregadorDialogController(ParseLinks, $localStorage, $timeout, $state, $window, $rootScope, $scope, $stateParams,
        entity, Empregador, $uibModal, Principal, Auth, BuscaCep, Upload, RamoAtuacaoEmpresa, AtributoGenerico,
        Termo, PlanoDestaque, Pessoa, pagingParams, ConfirmService, $location, $http, $sce, Curso, Parceiro, User) {
        var vm = this;


        vm.empregador = entity;
        vm.clear = clear;
        vm.abrirWhats = abrirWhats;
        vm.vincularVaga = vincularVaga;
        vm.save = save;
        vm.addSaldo = addSaldo;
        vm.addDestaque = addDestaque;
        vm.verExtrato = verExtrato;
        vm.addSaldoAdmin = addSaldoAdmin;
        vm.abreTermo = abreTermo;
        vm.transferirCredito = transferirCredito;
        vm.tornarParceiro = tornarParceiro;
        vm.buscaCep = buscaCep;
        vm.marcarNoMapa = marcarNoMapa;
        vm.carregarPessoasDaVaga = carregarPessoasDaVaga;
        vm.copyToClipboard = copyToClipboard;
        vm.inativarVaga = inativarVaga;
        vm.mudarQuantidadeVagasEmpresa = mudarQuantidadeVagasEmpresa;
        vm.esconderVaga = esconderVaga;
        vm.editarFilial = editarFilial;
        vm.removerEditarFilial = removerEditarFilial;
        vm.removerFilial = removerFilial;
        vm.comunicarCadastro = comunicarCadastro;
        vm.removerUser = removerUser;
        vm.novoCurso = novoCurso;
        vm.gravarVideo = gravarVideo;
        vm.deleteCurso = deleteCurso;
        vm.removerAnexo = removerAnexo;
        vm.salvarAnexoImagem = salvarAnexoImagem;
        vm.playVideo = playVideo;
        vm.editarCurso = editarCurso;
        vm.adicionarUsuario = adicionarUsuario;
        vm.selectPessoa = selectPessoa;
        vm.getPessoas = getPessoas;
        vm.carrgarParceirosDoEmpregador = carrgarParceirosDoEmpregador;
        vm.redefineSenhaIgualLogin = redefineSenhaIgualLogin;
        vm.salvaTab = salvaTab;
        vm.addParceiroAgrupado = addParceiroAgrupado;
        vm.removerParceiroTemporario = removerParceiroTemporario;

        vm.predicate = "ev.vaga.titulo";
        vm.reverse = "asc";
        vm.carregarVagas = carregarVagas;
        vm.addCidade = addCidade;

        vm.loadDashboard = loadDashboard;
        vm.goToDashboard = goToDashboard;
        vm.irParaVaga = irParaVaga;
        vm.urlDashboard = "";
        vm.activeTab = $localStorage.activeTab > 0 ? $localStorage.activeTab : 0;
        vm.vagas = [];
        vm.saldo = 0;
        vm.interesses = 0;
        vm.progressPercentage = null;
        vm.carregando = false;
        vm.fileOriginal = null;
        vm.imagemAnexo = null;
        vm.cropper = {sourceImage: null, croppedImage: null};
        vm.bounds = {left: 0, right: 0, top: 0, bottom: 0};
        vm.cidades = [];
        vm.cidadesSelecionadas = [];
        vm.filterVagas = "";
        vm.somenteVagasAtivas = true;

        vm.pessoaSelecionada;
        vm.usuarioSelecionado;
        vm.users = [];

        vm.glMarkers = [];
        vm.glCenter = {lat: -23.4248724, lng: -51.9265809};
        vm.glControls = {
            geolocate: {
                enabled: true,
                options: {
                    position: 'top-left'
                }
            }
        };

        vm.qntFuncionarios = [
            {codigo: 0, value: 'ENTRE_1_10', descricao: 'Entre 1 e 10'},
            {codigo: 0, value: 'ENTRE_11_100', descricao: 'Entre 11 e 100'},
            {codigo: 0, value: 'ENTRE_101_500', descricao: 'Entre 101 e 500'},
            {codigo: 0, value: 'ENTRE_501_1000', descricao: 'Entre 501 e 1000'},
            {codigo: 0, value: 'MAIS_1000', descricao: '1000 ou mais'},
        ];

        Termo.query({
            page: 0,
            size: vm.itemsPerPage,
        }, function (data) {
            vm.termos = data;
        });

        function addCidade(cidade) {
            if (vm.cidadesSelecionadas.indexOf(cidade) < 0) {
                vm.cidadesSelecionadas.push(cidade);
            } else {
                vm.cidadesSelecionadas.splice(vm.cidadesSelecionadas.indexOf(cidade), 1);
            }
            carregarVagas();
        }


        function salvaTab() {
            $localStorage.activeTab = vm.activeTab;
        }

        function carregarPlanosDestaque() {
            PlanoDestaque.getByEmpregador({id: vm.empregador.id}, function (data) {
                vm.planoDestaque = data;
            });
        }

        function getLotesEnviados() {
            Pessoa.getLotesEnvioMensagem({id: vm.empregador.id}, function (data) {
                vm.lotesTransmissao = data;
            });
        }


        function carregarCidades() {
            if (vm.empregador.endereco && vm.empregador.endereco.localidade) {
                var ciade = {nome: vm.empregador.endereco.localidade};
                vm.cidades.push(ciade);
                vm.cidadesSelecionadas.push(ciade);
            }
            if (vm.empregador.filiais) {
                for (var i = 0; i < vm.empregador.filiais.length; i++) {
                    var filial = vm.empregador.filiais[i];
                    if (filial.endereco.localidade) {
                        var cidade = {nome: filial.endereco.localidade};
                        var jaTem = false;
                        for (var j = 0; j < vm.cidades.length; j++) {
                            jaTem = vm.cidades[j].nome === cidade.nome;
                            if (jaTem) break;
                        }
                        if (!jaTem) {
                            vm.cidades.push(cidade);
                            vm.cidadesSelecionadas.push(cidade);
                        }
                    }
                }
            }
        }

        function irParaVaga(vaga) {
            var cidades = '';
            if (vm.cidadesSelecionadas) {
                for (var i = 0; i < vm.cidadesSelecionadas.length; i++) {
                    cidades += (i > 0 ? ', ' : '') + vm.cidadesSelecionadas[i].nome;
                }
            }
            $state.go('empregador-vaga', {
                vagaEmpregadorId: vaga.vagaEmpregadorId,
                id: vaga.vagaCorporateId,
                empregadorId: vm.empregador.id,
                cidades: cidades
            });
        }

        function goToDashboard() {
            $state.go('empregador-dashboard', {idParceiro: vm.idParceiro || (vm.empregador.parceiro ? vm.empregador.parceiro.id : null)}, {reload: true});
        }

        function loadDashboard() {
            vm.dashBoards = [];
            vm.dashBoards.push({
                nome: "Todas as Pessoas",
                url: $sce.trustAsResourceUrl('https://dashboard.myskills.com.br/public/question/e47e2069-ea3a-4b75-9004-c7e24fc096c3?empregador_id=' + vm.empregador.id)
            });
            vm.dashBoards.push({
                nome: "Profissões Mais Publicadas",
                url: $sce.trustAsResourceUrl('https://dashboard.myskills.com.br/public/question/f926cc17-28a8-4ffa-ab18-9bb50f3603c2?empregador_id=' + vm.empregador.id)
            });
            vm.dashBoards.push({
                nome: "Profissões Mais Procuradas",
                url: $sce.trustAsResourceUrl('https://dashboard.myskills.com.br/public/question/9633849b-7307-48b5-9ed3-4e034eff10b2?empregador_id=' + vm.empregador.id)
            });
            vm.dashBoards.push({
                nome: "Usuários Por Mês",
                url: $sce.trustAsResourceUrl('https://dashboard.myskills.com.br/public/question/92025192-6ece-4c5f-8cc8-dc47344f010e?empregador_id=' + vm.empregador.id)
            });
            vm.dashBoards.push({
                nome: "Empresas Por Mês",
                url: $sce.trustAsResourceUrl('https://dashboard.myskills.com.br/public/question/bcf06c19-8852-40e3-99bf-ac607c37c388?empregador_id=' + vm.empregador.id)
            });
            vm.dashBoards.push({
                nome: "Vagas por Empresa",
                url: $sce.trustAsResourceUrl('https://dashboard.myskills.com.br/public/question/afcb37b8-8fc7-4c9e-9cfd-1457dadad05a?empregador_id=' + vm.empregador.id)
            });

        }

        function carregarVagas() {
            if (vm.empregador.id) {
                Empregador.getVagasEmpresa({
                    empregadorId: vm.empregador.id,
                    cidades: vm.cidadesSelecionadas,
                    sort: vm.predicate + ' ' + (vm.reverse ? 'asc' : 'desc')
                }, function (data) {
                    vm.vagas = data;
                });
            }
        }

        function inativarVaga(vaga) {
            if (vaga.id) {
                Empregador.inativarVagasEmpresa({id: vaga.id}, function (data) {
                    carregarVagas();
                });
            }
        }

        function mudarQuantidadeVagasEmpresa(vaga) {
            if (vaga.quantidade < 0) {
                vaga.quantidade = 0;
            }
            if (vaga.id) {
                Empregador.mudarQuantidadeVagasEmpresa({id: vaga.id, quantidade: vaga.quantidade}, function (data) {
                    carregarVagas();
                });
            }
        }

        function esconderVaga(vaga) {
            if (vaga.id) {
                Empregador.esconderVagasEmpresa({id: vaga.id}, function (data) {
                    carregarVagas();
                });
            }
        }

        function carregarInteresses() {
            if (vm.empregador.id) {
                Empregador.buscarInteressePorEmpresa({id: vm.empregador.id}, function (data) {
                    vm.interesses = data.quantidade;
                });
            }
        }


        function buscarSaldo() {
            if (vm.empregador.id) {
                Empregador.getSaldo({id: vm.empregador.user.id}, function (data) {
                    vm.saldo = data.saldo;
                });
            }
        }

        function addSaldoAdmin() {

            vm.modalInstance = $uibModal.open({
                size: 'md',
                animation: true,
                templateUrl: 'addsaldo.dialog.html',
                controller: 'AddSaldoAoEmpregadorController',
                controllerAs: 'vm',
                resolve: {
                    empregador: vm.empregador
                }
            }).result.then(function (value) {
                Empregador.addSaldo({valor: value, idEmpregador: vm.empregador.user.id}, function () {
                    $state.go('empregador-edit', {id: vm.empregador.id}, {reload: true});
                }, function (error) {
                    if (error.status == 403) {
                        ConfirmService.confirm({
                                title: "Atenção!",
                                text: "Somente administradores podem adicionar saldo",
                                confirm: function () {
                                },
                                cancel: function () {
                                }
                            }
                        );
                    } else {
                        ConfirmService.confirm({
                                title: "Atenção!",
                                text: "Não foi possível adicionaro saldo." + error.message,
                                confirm: function () {
                                },
                                cancel: function () {
                                }
                            }
                        );
                    }

                });
            }, function () {
                $state.go('empregador-edit', {id: vm.empregador.id}, {reload: true});
            });
        }

        function abreTermo() {
            $uibModal.open({
                templateUrl: 'app/account/register/termos-aceite-dialog.html',
                controller: 'TermoAceiteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
            }).result.then(function (result) {
                vm.concordou = result && result.aceite;
            }, function () {

            });
        }


        function tornarParceiro() {
            vm.modalInstance = $uibModal.open({
                size: 'md',
                animation: true,
                templateUrl: 'parceiro.dialog.html',
                controller: 'TornarEmpregadorParceiroController',
                controllerAs: 'vm',
                resolve: {
                    empregador: vm.empregador
                }
            }).result.then(function (value) {
                Empregador.tornarParceiro({
                    idEmpregador: vm.empregador.id,
                    comissao: value
                }, function () {
                    $state.go('empregador-edit', {id: vm.empregador.id}, {reload: true});
                });
            }, function () {
                $state.go('empregador-edit', {id: vm.empregador.id}, {reload: true});
            });

        }

        function transferirCredito() {
            vm.modalInstance = $uibModal.open({
                size: 'md',
                animation: true,
                templateUrl: 'transferircreditos.dialog.html',
                controller: 'TransfererirCreditosController',
                controllerAs: 'vm',
                resolve: {
                    empregador: vm.empregador
                }
            }).result.then(function (transferencia) {
                Empregador.transferirCredito({
                    de: vm.empregador.user.id,
                    para: transferencia.empregador.user.id,
                    valor: transferencia.valorTransferencia
                }, function () {
                    loadAll();
                });
            }, function () {
                $state.go('empregador-edit', {id: vm.empregador.id}, {reload: true});
            });
        }

        function addSaldo() {
            // if (vm.empregador.id) {
            //     $uibModal.open({
            //         templateUrl: 'app/entities/empregador/empregador-escolher-plano-dialog.html',
            //         controller: 'EmpregadorEscolherPlanoController',
            //         controllerAs: 'vm',
            //         size: 'lg',
            //         resolve: {
            //             entity: ['PlanoCredito', function (PlanoCredito) {
            //                 //return User.get({login: $stateParams.login});
            //                 return null;
            //             }]
            //         }
            //     }).result.then(function (plano) {
            //         console.log("plano: ", plano);
            //         $state.go('comprar-saldo', {id: plano.id}, {reload: true});
            //
            //     }, function () {
            //         //$state.go('^');
            //         $state.go('comprar-saldo', null, {reload: true});
            //     });
            // }
        }


        function addDestaque() {
            if (vm.empregador.id) {
                $uibModal.open({
                    templateUrl: 'app/entities/empregador/empregador-escolher-destaque-dialog.html',
                    controller: 'EmpregadorEscolherDestaqueController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: ['PlanoCredito', function (PlanoCredito) {
                            //return User.get({login: $stateParams.login});
                            return null;
                        }]
                    }
                }).result.then(function (plano) {
                    ConfirmService.confirm({
                            title: "Destaque-se!",
                            text: "Deseja destacar sua empresa por " + plano.quantidade + " dias na página de OPORTUNIDADES? Ao confirmar será debitado o valor de " + plano.valorPlano + " créditos do seu saldo.",
                            confirm: function () {
                                Empregador.comprarDestaque({
                                    destaqueId: plano.id,
                                    empregadorId: vm.empregador.id
                                }, function (data) {
                                    vm.carregando = true;
                                    loadAll();
                                    $timeout(function () {
                                        vm.carregando = false;
                                        ConfirmService.confirm({
                                                title: "Sucesso!",
                                                text: "Sua empresa aparecerá em destaque na página de OPORTUNIDADES.",
                                                confirm: function () {
                                                },
                                                cancel: function () {
                                                }
                                            }
                                        );
                                    }, 3000);
                                }, function (error) {
                                    alert("Não foi possível concluir a operação, verifique seu saldo");
                                });
                            },
                            cancel: function () {
                            }
                        }
                    );


                }, function () {
                    //$state.go('^');
                    // $state.go('comprar-saldo', null, {reload: true});
                });
            }
        }


        function verExtrato() {
            if (vm.empregador.id) {
                $uibModal.open({
                    templateUrl: 'app/entities/empregador/empregador-extrato-dialog.html',
                    controller: 'EmpregadorExtratoDialogController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: ['PlanoCredito', function (PlanoCredito) {
                            return vm.empregador;
                        }]
                    }
                });
            }
        }

        vm.pageEmpregadoresParceiro = 0;
        vm.pageSizeEmpregadoresParceiros = 20;


        function redefineSenhaIgualLogin(parceiro) {
            ConfirmService.confirm({
                title: "Atenção",
                text: "Deseja definir a senha desse usuário para que fique igual ao login?",
                confirm: function () {
                    User.redefineSenhaIgualLogin({login: parceiro.cnpj});
                },
                cancel: function () {

                }
            });
        }

        function carrgarParceirosDoEmpregador(page) {
            vm.pageEmpregadoresParceiro = page;
            if (vm.empregador.parceiro) {
                vm.carregandoParceiros = true;
                Empregador.contarEmpregadoresDoParceiro({
                    parceiroId: vm.empregador.id, nome: vm.filterParceiros
                }, function (data) {
                    vm.totalEmpregadoresDoParceiro = data.total;
                    Empregador.buscarEmpregadoresDoParceiro({
                        parceiroId: vm.empregador.id,
                        page: vm.pageEmpregadoresParceiro,
                        pageSize: vm.pageSizeEmpregadoresParceiros,
                        nome: vm.filterParceiros
                    }, function (data) {
                        vm.empregadoresDoParceiro = data;
                        vm.carregandoParceiros = false;
                    }, function (error) {
                        vm.carregandoParceiros = false;
                    });
                });

            }

        }

        function carregarUsuario() {
            if (vm.empregador.id) {
                Principal.identity().then(function (account) {
                    vm.account = account;
                    vm.isAuthenticated = Principal.isAuthenticated();
                    carregarVagas();
                    carregarPlanosDestaque();
                    buscarSaldo();
                    ajustarTamanhosQuadrosTodo();
                    contarPessoas();
                    getLotesEnviados();
                });
                carregarUsuarios();
            }
        }


        function clear() {
            vm.isSaving = false;
            vm.editando = false;
        }

        function abrirWhats(telefone) {
            $window.open(" https://wa.me/" + (telefone.startsWith('55') ? telefone : '55' + telefone), "_blank");
        }

        function save() {

            vm.isSaving = true;

            function saveEmpregador(logoMarca) {
                if (logoMarca) {
                    vm.empregador.logo = logoMarca;
                }
                if (vm.empregador.id !== null) {
                    Empregador.update(vm.empregador, onSaveSuccess, onSaveError);
                } else {
                    Empregador.create(vm.empregador, onSaveSuccess, onSaveError);
                }
            }

            if (vm.idParceiro) {
                vm.empregador.user.idParceiro = vm.idParceiro;
            }

            if (vm.fileOriginal) {
                upload(vm.fileOriginal, saveEmpregador);
                console.log("logo do maluco", vm.empregador.logo);
            } else {
                saveEmpregador();
            }
        }

        function upload(blobInfo, callback) {
            console.log(" iniciou uma chamada ", blobInfo);
            vm.progressPercentage = 0;
            Upload.upload({
                url: '/api/upload/imagem',
                data: {
                    file: blobInfo,
                }
            }).then(function (resp) {
                console.log(" finalizando a chamada ");
                callback(resp.data.conteudoFormatado);
            }, function (resp) {
                console.log('Error status: ' + resp.status);
                vm.progressPercentage = null;
            }, function (evt) {
                vm.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + vm.progressPercentage + '% ' + evt.config.data.file.name);
            });
        };

        function buscaCep() {
            vm.isSaving = true;

            if (vm.empregador && vm.empregador.endereco && vm.empregador.endereco.cep && vm.empregador.endereco.cep.length == 8) {
                BuscaCep.get({cep: vm.empregador.endereco.cep}, onBuscaCepSuccess, onBuscaCepError);
            } else {
                vm.isSaving = false;
            }
        }

        function onBuscaCepError() {
            vm.isSaving = false;
        }


        function onBuscaCepSuccess(result) {
            if (result.logradouro) {
                vm.empregador.endereco.logradouro = result.logradouro;
                vm.empregador.endereco.bairro = result.bairro;
                vm.empregador.endereco.localidade = result.localidade;
                vm.empregador.endereco.uf = result.uf;

                marcarNoMapa();
            }
            vm.isSaving = false;
        }

        function marcarNoMapa() {
            if (vm.empregador.endereco.localidade && vm.empregador.endereco.logradouro) {
                var endereco =
                    vm.empregador.endereco.logradouro + ", " +
                    vm.empregador.endereco.numero + ", " +
                    vm.empregador.endereco.localidade + " ," +
                    vm.empregador.endereco.uf
                ;
                Empregador.getGeoFromAddress({address: endereco}, function (data) {
                    vm.glMarkers = [];
                    console.log(data);

                    var element = document.createElement('div');
                    element.style.background = 'url(https://w7.pngwing.com/pngs/432/772/png-transparent-computer-icons-geo-fence-computer-software-others-miscellaneous-geolocation-black-and-white.png)  no-repeat';
                    element.style.backgroundSize = 'contain';
                    element.style.backgroundPosition = 'center';
                    element.style.width = 50 + 'px';
                    element.style.height = 50 + 'px';
                    element.style.borderRadius = '10%';


                    if (data && data.features) {
                        vm.glMarkers.push({
                            coordinates: data.features[1].geometry.coordinates,
                            element: element,
                        });
                    }
                });
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:empregadorUpdate', result);
            vm.isSaving = false;
            vm.editando = false;
            if (result.id && vm.atributos) {
                var agrupador = {vinculo: result.id, tipo: 'EMPREGADOR', atributos: []};
                for (var i = 0; i < vm.atributos.length; i++) {
                    var atributo = vm.atributos[i];
                    if (atributo && atributo.valor) {
                        agrupador.atributos.push({
                            atributo: atributo,
                            tipoVinculo: agrupador.tipo,
                            vinculo: agrupador.vinculo,
                            valor: atributo.tipoCampo === 'MULTIPLO' ? atributo.valor.join(',') : atributo.valor
                        });
                    }
                }
                if (agrupador.atributos) {
                    AtributoGenerico.saveVinculos(agrupador);
                }
            }

            if (!vm.isAuthenticated) {
                Auth.login({
                    username: vm.empregador.cnpj,
                    password: vm.empregador.user.password
                }).then(function () {

                    $rootScope.$broadcast('authenticationSuccess');
                    $state.go('empregador', null, {reload: true});
                });
            } else {
                Principal.hasAuthority("ROLE_ADMIN")
                    .then(function (result) {
                        if (result) {
                            $state.reload();
                        } else {
                            $state.go('empregador', null, {reload: true});
                        }
                    });

            }

        }

        function onSaveError(error) {
            vm.isSaving = false;
            console.error("Error>>>>", error);
            var mensagemErro = '';
            if (error.data.message) {
                var erro = error.data.message;
                if (erro.indexOf("jhi_user_email_key") !== -1) {
                    mensagemErro = "O email " + vm.empregador.email + " já está sendo utilizado no sistema.";
                } else if (erro.indexOf("jhi_user_login_key") !== -1) {
                    mensagemErro = "O CNPJ " + vm.empregador.cnpj + " já está sendo utilizado no sistema.";
                } else {
                    mensagemErro = erro;
                }
            } else {

            }
            ConfirmService.confirm({
                    title: "Atenção!",
                    text: mensagemErro ? mensagemErro : "Não foi possível continuar.",
                    confirm: function () {
                    },
                    cancel: function () {
                    }
                }
            );
        }

        function carregarPessoasDaVaga(vaga) {
            $uibModal.open({
                templateUrl: 'app/entities/empregador/pessoa-vaga.html',
                controller: 'PessoaVagaDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Empregador', function (Empregador) {
                        return vaga;
                    }]
                }
            }).result.then(function (result) {
                // $state.reload(true);
            }, function () {
                // $state.reload(true);
            });
        }

        function removerFilial(index) {
            vm.empregador.filiais.splice(index, 1);
            Empregador.update(vm.empregador, onSaveSuccess, onSaveError);
        }

        function comunicarCadastro() {
            Empregador.comunicarCadastro({id: vm.empregador.id});
        }

        function removerEditarFilial(index) {
            $uibModal.open(modalFilial(vm.empregador.filiais[index].endereco)).result.then(function (result) {
                if (!vm.empregador.filiais) {
                    vm.empregador.filiais = [];
                }
                vm.empregador.filiais.splice(index, 1);
                vm.empregador.filiais.push({endereco: result});
                Empregador.update(vm.empregador, onSaveSuccess, onSaveError);
                // $state.reload(true);
            }, function () {
                // $state.reload(true);
            });
        }

        function modalFilial(endereco) {
            return {
                templateUrl: 'app/entities/empregador/empregador-filial-dialog.html',
                controller: 'FilialEmpregadorController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Empregador', function (Empregador) {
                        return endereco;
                    }]
                }
            };
        }

        function editarFilial(endereco) {
            $uibModal.open(modalFilial(endereco)).result.then(function (result) {
                if (!vm.empregador.filiais) {
                    vm.empregador.filiais = [];
                }
                vm.empregador.filiais.push({endereco: result});
                Empregador.update(vm.empregador, onSaveSuccess, onSaveError);
                // $state.reload(true);
            }, function () {
                // $state.reload(true);
            });
        }

        function copyToClipboard(url) {
            url = "https://personal.myskills.com.br/#/home/empregador/" + vm.empregador.id;

            if (window.clipboardData && window.clipboardData.setData) {
                // IE specific code path to prevent textarea being shown while dialog is visible.
                clipboardData.setData("Text", url);
            } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                var textarea = document.createElement("textarea");
                textarea.textContent = url;
                textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    document.execCommand("copy");  // Security exception may be thrown by some browsers.
                } catch (ex) {
                    console.warn("Copy to clipboard failed.", ex);
                } finally {
                    document.body.removeChild(textarea);
                }
            }

            ConfirmService.confirm({
                    title: "Link Copiado!",
                    text: "O Link está na sua área de transferênia: " + url,
                    confirm: function () {
                    },
                    cancel: function () {
                    }
                }
            );

        }

        function vincularVaga() {
            $uibModal.open({
                templateUrl: 'app/entities/empregador/vincular-vaga.html',
                controller: 'VincularVagaDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Empregador', function (Empregador) {
                        return vm.empregador;
                    }]
                }
            }).result.then(function (result) {
                $state.reload(true);
            }, function () {
                $state.reload(true);
            });
        }


        function carregaImagem(base64Img) {
            vm.carregando = false;
            vm.fileOriginal = base64Img;
            vm.cropper.sourceImage = vm.fileOriginal;
        }

        function toDataURL(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }

        function rotateImage() {
            vm.carregando = true;
            convertImage(function () {
                vm.carregando = false;
            });
        }

        function convertImage(callback) {
            var img = new Image();
            img.src = vm.cropper.sourceImage;
            var canvas = document.createElement('CANVAS');
            var ctx = canvas.getContext("2d");
            canvas.width = img.height;
            canvas.height = img.width;
            ctx.drawImage(img, 0, 0);
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(90 * Math.PI / 180);
            ctx.drawImage(img, -img.width / 2, -img.height / 2);
            vm.cropper.sourceImage = canvas.toDataURL();
            callback();
        }

        function ajustarTamanhosQuadrosTodo() {
            // $timeout(function () {
            //     if (vm.empregador.id) {
            //         var tamanhoSaldo = document.getElementById("box-saldo").offsetHeight;
            //         document.getElementById("box-destaque").style.height = tamanhoSaldo + "px";
            //         document.getElementById("box-interesses").style.height = tamanhoSaldo + "px";
            //     }
            // }, 1000);

        }

        function contarPessoas() {
            Pessoa.count({}, function (data) {
                vm.totalPessoas = data;
            });
            Empregador.countPremium({id: vm.empregador.id}, function (data) {
                vm.totalPremiums = data;
            });
        }

        function getPessoas(param) {
            return $http.get('api/_search/pessoas/', {
                params: {
                    query: param,
                    page: 0,
                    size: 10,
                }
            }).then(function (response) {
                return response.data.map(function (pessoa) {
                    return pessoa;
                });
            });
        }

        function selectPessoa($item, $model, $label, $event) {
            if ($model.id) {
                console.log('user selecionado : ', $model);
                Pessoa.getUserByPessoaId({id: $model.id}, function (response) {
                    console.log("response: ", response);
                    var userAdmin = {};
                    userAdmin.idParceiro = vm.empregador.id;
                    userAdmin.idUsuario = response.id;
                    userAdmin.usuario = response.firstName;
                    userAdmin.email = response.email;

                    vm.usuarioSelecionado = userAdmin;

                }, function (error) {
                    console.log("erro:", error);
                });
                //$state.go("pessoa-detail", {id: $model.id});
            }
        }


        function adicionarUsuario(usuario) {

            Empregador.adicionarUsuarioAdministrador(usuario, function (data) {
                vm.users.push(usuario);
                vm.pessoaSelecionada = null;
            }, function (error) {
                console.log("erro: ", error);
                if (error.status === 409) {
                    ConfirmService.confirm({
                            title: "Ops!",
                            text: "O usuário " + usuario.usuario + " já é administrador de outro parceiro.",
                            confirm: function () {
                            },
                            cancel: function () {
                            }
                        }
                    );
                } else {
                    ConfirmService.confirm({
                            title: "Ops!",
                            text: "Erro ao vincular o usuário " + usuario.usuario + " como administrador do parceiro.",
                            confirm: function () {
                            },
                            cancel: function () {
                            }
                        }
                    );
                }
            });
        }

        function carregarUsuarios() {
            Empregador.getUsuariosAdministradores({id: vm.empregador.id}, function (data) {
                vm.users = data;
            });
        }

        function removerUser(user, index) {
            Empregador.removerUsuarioEmpregador(user, function (data) {
                vm.users.splice(index, 1);
            }, function (error) {
                console.log("erro: ", error);
                if (error.status === 409) {
                    ConfirmService.confirm({
                            title: "Ops!",
                            text: "O Empregador não pode ficar sem administrador.",
                            confirm: function () {
                            },
                            cancel: function () {
                            }
                        }
                    );

                } else {
                    ConfirmService.confirm({
                            title: "Ops!",
                            text: "Erro ao remover o usuário do empregador.",
                            confirm: function () {
                            },
                            cancel: function () {
                            }
                        }
                    );
                }
            });
        }

        function novoCurso() {
            editarCurso({
                parceiro: vm.empregador.parceiro,
                nome: null,
                id: null
            });
        }

        function buscarAnexos() {
            Empregador.buscarAnexo({id: vm.empregador.id}, function (data) {
                vm.anexos = data;
                $timeout(function () {
                    for (var i = 0; i < vm.anexos.length; i++) {
                        var anexo = vm.anexos[i];
                        var video = document.getElementById('video-preview-' + anexo.id);
                        video.src = null;
                        video.muted = false;
                        video.volume = 1;
                        video.src = anexo.conteudo;
                    }
                }, 2000);
            });
        }

        function removerAnexo(anexo) {
            ConfirmService.confirm({
                    title: "Atenção!",
                    text: "Tem certeza que deseja remover esse anexo?",
                    confirm: function () {
                        Empregador.deleteAnexo({id: anexo.id}, buscarAnexos);
                    },
                    cancel: function () {
                    }
                }
            );

        }

        function salvarAnexoImagem() {
            vm.carregandoImagem = true;
            upload(vm.imagemAnexo, function (imagem) {

                ConfirmService.confirmInput({
                    title: "Digite um Titulo!",
                    text: "Informe um título para essa imagem",
                    confirm: function (message) {
                        var anexo = {
                            empregador: vm.empregador,
                            conteudo: imagem,
                            tipo: 'IMAGEM',
                            titulo: message.confirmValue,
                        };
                        Empregador.salvarAnexo(anexo, buscarAnexos);
                        vm.carregandoImagem = false;
                        vm.imagemAnexo = null;
                    },
                    cancel: function () {
                    }
                });


            }, function () {
                vm.carregandoImagem = false;
            });
        }


        function playVideo(video) {
            $uibModal.open({
                templateUrl: 'app/entities/video/video-detail.html',
                controller: 'VideoDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    video: function () {
                        return video;
                    }
                }
            }).result.then(function (result) {

            }, function () {

            });
        }

        function deleteCurso(curso){
            $uibModal.open({
                templateUrl: 'app/entities/curso/curso-delete-dialog.html',
                controller: 'CursoDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['Curso', function (Curso) {
                        return Curso.get({id: curso.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('curso');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {
                loadAll();
            }, function () {
                loadAll();
            });
        }

        function gravarVideo() {
            $uibModal.open({
                templateUrl: 'app/entities/video/video-edit.html',
                controller: 'VideoController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
            }).result.then(function (result) {

                ConfirmService.confirmInput({
                    title: "Digite um Titulo!",
                    text: "Informe um título para esse vídeo",
                    confirm: function (message) {
                        var anexo = {
                            empregador: vm.empregador,
                            titulo: message.confirmValue,
                            conteudo: result.data.conteudoFormatado,
                            tipo: 'VIDEO'
                        };
                        Empregador.salvarAnexo(anexo, buscarAnexos);
                    },
                    cancel: function () {
                    }
                });


            }, function () {
                console.log("VORTOO >>>");
            });
        }

        function editarCurso(curso) {
            $uibModal.open({
                templateUrl: 'app/entities/curso/curso-dialog.html',
                controller: 'CursoDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        curso.parceiro = vm.empregador.parceiro;
                        return curso;
                    }
                }
            }).result.then(function () {
                carregarCursos();
            }, function () {
                carregarCursos();
            });
        }

        function carregarCursos() {
            vm.cursosPage = vm.cursosPage || 0;
            if (vm.empregador.parceiro) {
                Curso.query({
                    id: vm.empregador.parceiro.id,
                    page: vm.cursosPage,
                    size: vm.itemsPerPage,
                }, function (data, headers) {
                    vm.linksCursos = ParseLinks.parse(headers('link'));
                    vm.totalCursos = headers('X-Total-Count');
                    vm.cursosCount = vm.totalItems;
                    vm.cursos = data;
                });
            }
        }

        function carregarParceiro() {
            var host = $location.host();
            vm.subdomain = host.split('.')[0];
            Parceiro.getPublico({identificador: vm.subdomain}, function (data) {
                vm.parceiro = data;
                vm.idParceiro = vm.parceiro.id;
                loadAll();
            }, loadAll);
        }

        function carregarParceirosAgrupados() {
            Empregador.getParceirosAgrupados(
                {idUsuario: vm.empregador.user.id}, function (data) {
                    vm.parceirosAgrupados = data;
                }
            );
        }

        function removerParceiroTemporario(parceiro) {
            Empregador.removerParceirosAgrupados(parceiro, carregarParceirosAgrupados);
        }

        function addParceiroAgrupado() {
            vm.modalInstance = $uibModal.open({
                size: 'md',
                animation: true,
                templateUrl: 'parceiro.temporario.dialog.html',
                controller: 'ParceiroTemporarioController',
                controllerAs: 'vm',
                resolve: {
                    empregador: vm.empregador
                }
            }).result.then(function (value) {
                Empregador.addParceirosAgrupados({
                    usuario: vm.empregador.user,
                    parceiro: value
                }, carregarParceirosAgrupados);
            }, function () {
                $state.go('empregador-edit', {id: vm.empregador.id}, {reload: true});
            });

        }

        function loadAll() {
            carregarUsuario();
            carregarCidades();
            carregarInteresses();
            carregarCursos();
            buscarAnexos();
            carregarParceirosAgrupados();
        }

        carregarParceiro();

    }

    angular
        .module('mySkillsPersonalApp')
        .controller('TornarEmpregadorParceiroController', TornarEmpregadorParceiroController);

    TornarEmpregadorParceiroController.$inject = ['$uibModalInstance', 'empregador'];

    function TornarEmpregadorParceiroController($uibModalInstance, empregador) {
        var vm = this;
        vm.empregador = empregador;
        vm.comissao = 0;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.salvar = function () {
            $uibModalInstance.close(vm.comissao);
        };

    }


    angular
        .module('mySkillsPersonalApp')
        .controller('ParceiroTemporarioController', ParceiroTemporarioController);

    ParceiroTemporarioController.$inject = ['$uibModalInstance', 'Parceiro'];

    function ParceiroTemporarioController($uibModalInstance, Parceiro) {
        var vm = this;
        vm.search = '';

        vm.load = function () {
            Parceiro.query({filtro: vm.search}, function (data) {
                vm.parceiros = data;
            });
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.selecionar = function (parceiro) {
            vm.selecionado = parceiro;
        };

        vm.salvar = function () {
            $uibModalInstance.close(vm.selecionado);
        };

        vm.load();
    }


    angular
        .module('mySkillsPersonalApp')
        .controller('AddSaldoAoEmpregadorController', AddSaldoAoEmpregadorController);

    AddSaldoAoEmpregadorController.$inject = ['$uibModalInstance', 'empregador'];

    function AddSaldoAoEmpregadorController($uibModalInstance, empregador) {
        var vm = this;
        vm.empregador = empregador;
        vm.saldo = 0;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.salvar = function () {
            $uibModalInstance.close(vm.saldo);
        };

    }


    angular
        .module('mySkillsPersonalApp')
        .controller('TransfererirCreditosController', TransfererirCreditosController);

    TransfererirCreditosController.$inject = ['$uibModalInstance', 'empregador', 'Empregador'];

    function TransfererirCreditosController($uibModalInstance, empregador, Empregador) {
        var vm = this;
        vm.empregador = empregador;
        vm.podeTransferir = false;
        vm.valorTransferencia = 0;


        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        Empregador.getSaldo({id: vm.empregador.user.id}, function (data) {
            vm.saldo = data.saldo;
        });


        vm.salvar = function () {
            $uibModalInstance.close({valorTransferencia: vm.valorTransferencia, empregador: vm.destino});
        };

        function addMessage(exibe, tranfere, msg) {
            vm.mensagem.exibe = exibe;
            vm.mensagem.podeTransferir = tranfere;
            vm.mensagem.texto = msg;
        }

        vm.validar = function () {
            vm.mensagem = {exibe: false, texto: "", podeTransferir: false};
            if (!vm.cnpjDestinoTransferencia) {
                addMessage(true, false, "Informe o CNPJ para o crédito ser tranferido!");
                return;
            }
            if (!vm.valorTransferencia || vm.valorTransferencia > vm.saldo) {
                addMessage(true, false, "Informe o valor em créditos a serem tranferidos, deve ser menor que o seu saldo!");
                return;
            }
            Empregador.filtrar({cnpj: vm.cnpjDestinoTransferencia}, function (response) {
                if (!response || response.length < 1 || response[0].cnpj != vm.cnpjDestinoTransferencia) {
                    addMessage(true, false, "Não foi possível encontrar uma empresa com o CNPJ: " + vm.cnpjDestinoTransferencia + "!");
                } else {
                    vm.destino = response[0];
                    addMessage(true, true, "Tem certeza que deseja transferir " + vm.valorTransferencia + " créditos para " + vm.destino.razaoSocial + "?");
                }
            }, function (error) {
                addMessage(true, false, "Não foi possível encontrar uma empresa com o CNPJ: " + vm.cnpjDestinoTransferencia + "!");

            });
        };

    }
})();
